/**
 * original founction library
 * [usage]
 * import moment from '@/assets/lib/moment'
 * mixins: [moment]
 */
import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  methods: {
    /**
      * @param {Date} time new Date形式の日付
      * @param {String} format 変換したいフォーマット
      * @return {String} 日付情報
      */
    formatted (time, format) {
      return moment(time).format(format)
    },
    /**
     * @param {String} format 変換したいフォーマット
     * @return {String} 月末の日付情報
     */
    endOfDate (format) {
      const time = moment().endOf('month')
      return this.formatted(time, format)
    }
  }
})
